/**
 * @description 登录、获取用户信息、退出登录、清除token逻辑，不建议修改
 */
 import { getToken, removeToken, setToken } from '@/utils/token'
 
 const state = () => ({
   token: getToken(),
 })
 const getters = {
   token: (state) => state.token
 }
 const mutations = {
   /**
    * @description 设置token
    * @param {*} state
    * @param {*} token
    */
   setToken(state, token) {
     state.token = token
     setToken(token)
   },
 }
 const actions = {
   async resetAll({ commit, dispatch }) {
     console.log(commit)
     await dispatch('setToken', '')
     removeToken()
   },
   /**
    * @description 设置token
    * @param {*} { commit }
    * @param {*} token
    */
   setToken({ commit }, token) {
     commit('setToken', token)
   },
 }
 export default { state, getters, mutations, actions }
 